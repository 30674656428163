import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import SimpleCrypto from "simple-crypto-js";

@Injectable()
export class ClientsService {
	public currentClient: BehaviorSubject<any> = new BehaviorSubject(null);
	public clientesTodos: BehaviorSubject<any> = new BehaviorSubject([]);
	public legalTodos: BehaviorSubject<any> = new BehaviorSubject(null);

	public setCurrentClient(cliente): void {
		this.currentClient.next(cliente);
	}
	public getCurrentClient(): BehaviorSubject<any> {
		return this.currentClient;
	}
	public setClientesTodos(val): void {
		this.clientesTodos.next(val);
	}
	public getClientesTodos(): Subject<any> {
		return this.clientesTodos;
	}

	public getLegalTodos(): Subject<any> {
		return this.legalTodos;
	}
	public setLegalTodos(val): void {
		this.legalTodos.next(val);
	}

	public agregarCliente(cliente): void {
		const currentValue = this.clientesTodos.value;
		const updatedValue = [...currentValue, cliente];
		this.clientesTodos.next(updatedValue);
	}
	public getUser(): string | any {
		const user = localStorage.getItem("u");
		const _user = new SimpleCrypto("user");
		return _user.decrypt(user);
	}
	public setUser(response): string | any {
		const simpleCrypto = new SimpleCrypto("user");
		return simpleCrypto.encrypt(response.role);
	}
	public tiposDeEstatusCredito(): Array<string> {
		return ["Convenio Extrajudicial", "Promesa de pago", "Negativa de pago", "Reagendar visita", "Turnar Legal", "Borrar"];
	}

	public tareasJuzgado(): Array<string> {
		return ["Presentar Demanda", "Recoger folio nuevo emplazamiento", "Promoción correción domicilio", "Solicitar fuerza pública", "Presentar boleta fuerza pública en juzgado", "Recoger oficio FP", "Designar depositario y solicita entrega voluntaria", "Ratificar Depositario", "Solicitar FP y secuestro de bienes", "SolicitarRemate", "Devolución de documento", "Ratificación", "Agendar devolución de documentos", "Recoger documentos"];
	}
	public tareasCampo(): Array<string> {
		return ["Ejecutar emplazamineto", "Ejecutar fuerza pública", "Presentar oficio FP en seguridad pública", "Construir actuario", "Turnar folios emplazamiento", "Turnar demandas (agendar emplazamiento)", "Turnar folios camio de domicilio", "Agendar fuerza pública", "Agendar secuestro de bienes", "Ratificar desistimento", "Turnar folios señalamiento", "Turnar folios sentencia"];
	}
}
