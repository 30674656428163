// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from "../core/interfaces/config";

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: "Clientes",
						icon: "flaticon-users-1",
						page: "/viewAll/clients",
						display: false,
					},
					{
						title: "Usuarios",
						icon: "flaticon-user-ok",
						page: "/register",
						display: false,
					},
					{
						title: "Asesores",
						icon: "flaticon-user-add",
						page: "/asesores",
						display: false,
					},
					{
						title: "Empleados",
						icon: "flaticon-user-settings",
						page: "/RH/editarEmpleados",
						display: false,
					},
					{
						title: "Ajustes",
						icon: "flaticon-more-1",
						page: "/ajustes",
						display: false,
					},
				],
			},
			aside: {
				self: {},
				items: [
					{
						title: "Creditos",
						section: "Creditos",
						desc: "Some description goes here",
						root: true,
						icon: "flaticon-line-graph",
						translate: "MENU.DASHBOARD",
						submenu: [
							{
								title: "Información del cliente",
								page: "/credit/client",
							},
							{
								title: "Información del crédito",
								page: "/credit/credit",
							},
							{
								title: "Evaluación de crédito",
								page: "/credit/creditEval",
							},
							{
								title: "Verificación",
								page: "/credit/verificacion",
							},
						],
					},
					{
						title: "Legal",
						desc: "Some description goes here",
						root: true,
						icon: "flaticon-security",
						translate: "MENU.DASHBOARD",
						submenu: [
							{
								title: "Status Juicio",
								page: "/legal/statusJuicio",
							},
							{
								title: "Agenda de Actividades",
								page: "/legal/agendaActividades",
							},
							{
								title: "Convenios",
								page: "/legal/convenios",
							},
						],
					},
					{
						title: "Recursos Humanos",
						desc: "Some description goes here",
						root: true,
						icon: "flaticon-map",
						translate: "MENU.DASHBOARD",
						submenu: [
							{
								title: "Pendientes",
								page: "/RH/pendientes",
							},
						],
					},
					{
						title: "Reportes",
						desc: "Some description goes here",
						root: true,
						icon: "flaticon-doc",
						translate: "MENU.DASHBOARD",
						submenu: [
							{
								title: "Metas",
								page: "/reportes/metas",
							},
							{
								title: "Buro",
								page: "/reportes/buro",
							},
						],
					},
					// {
					// 	title: "Finanzas",
					// 	desc: "Some description goes here",
					// 	root: true,
					// 	icon: "flaticon-graphic",
					// 	translate: "MENU.DASHBOARD",
					// 	submenu: [
					// 		{
					// 			title: "Ingresos",
					// 			page: "/finanzas/ingresos",
					// 		},
					// 		{
					// 			title: "Egresos",
					// 			page: "/finanzas/egresos",
					// 		},
					// 		{
					// 			title: "Balance General",
					// 			page: "/finanzas/balance",
					// 		},
					// 	],
					// },
					{
						title: "Tickets",
						root: true,
						icon: "flaticon-graphic",
						translate: "MENU.DASHBOARD",
						page: "/tickets",
					},
				],
			},
		};
	}
}
