import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/credit/client': {
				page: {
					title: 'Información de cliente'
				}
			},
			'/credit/credit': {
				page: {
					title: 'Información de credito'
				}
			},
			'/credit/creditEval': {
				page: {
					title: 'Evaluacionde credito'
				}
			},
			// profile: {
			// 	page: { title: 'User Profile', desc: '' }
			// },
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
