import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../auth/authentication.service";
import { ClientsService } from "../../content/pages/components/shared/clients.service";

@Injectable()
export class GuardService implements CanActivate {
	constructor(private authenticationService: AuthenticationService, private clientsService: ClientsService) {}

	private COMMON_INACTIVE_ROUTES = ["/register", "/asesores", "/ajustes"];
	private ASISTENTE_INACTIVE_ROUTES = [...this.COMMON_INACTIVE_ROUTES, "/RH/pendientes", "/RH/editarEmpleados", "/finanzas/ingresos", "/finanzas/egresos", "/finanzas/balance"];
	private CREDITO_INACTIVE_ROUTES = [
		...this.COMMON_INACTIVE_ROUTES,
		// legal
		"/legal/convenios",
		"/legal/statusJuicio",
		"/legal/agendaActividades",
		// recursos humanos
		"/RH/pendientes",
		"/RH/editarEmpleados",
		// reportes
		"/reportes/metas",
		"/reportes/buro",
		// finanzas
		"/finanzas/ingresos",
		"/finanzas/egresos",
		"/finanzas/balance",
	];
	private LEGAL_INACTIVE_ROUTES = [
		...this.COMMON_INACTIVE_ROUTES,
		// recursos humanos
		"/RH/pendientes",
		"/RH/editarEmpleados",
		// reportes
		"/reportes/metas",
		"/reportes/buro",
		// finanzas
		"/finanzas/ingresos",
		"/finanzas/egresos",
		"/finanzas/balance",
	];
	private VERIFICACION_INACTIVE_ROUTES = [
		...this.COMMON_INACTIVE_ROUTES,
		// clientes
		"/viewAll/clients",
		"viewAll/clientInfo/",
		// credito
		"/credito/client",
		"/credito/credit",
		"/credito/creditEval",
		// legal
		"/legal/convenios",
		"/legal/statusJuicio",
		"/legal/agendaActividades",
		// recursos humanos
		"/RH/pendientes",
		"/RH/editarEmpleados",
		// reportes
		"/reportes/metas",
		"/reportes/buro",
		// finanzas
		"/finanzas/ingresos",
		"/finanzas/egresos",
		"/finanzas/balance",
	];
	private COBRANZA_INACTIVE_ROUTES = [
		...this.COMMON_INACTIVE_ROUTES,
		// credito
		"/credito/client",
		"/credito/credit",
		"/credito/creditEval",
		"/credito/verificacion",
		// legal
		"/legal/convenios",
		"/legal/statusJuicio",
		"/legal/agendaActividades",
		// recursos humanos
		"/RH/pendientes",
		"/RH/editarEmpleados",
		// reportes
		"/reportes/buro",
		// finanzas
		"/finanzas/ingresos",
		"/finanzas/egresos",
		"/finanzas/balance",
	];
	private LEGAL_COBRANZA_INACTIVE_ROUTES = [...this.COMMON_INACTIVE_ROUTES, ...this.COBRANZA_INACTIVE_ROUTES];
	private ASISTENTE_BASICO_INACTIVE_ROUTES = [...this.COMMON_INACTIVE_ROUTES, ...this.COBRANZA_INACTIVE_ROUTES];

	// Solo asesor y admin pueden ver tickets
	private ASESOR_INACTIVE_ROUTES = [...this.COMMON_INACTIVE_ROUTES, ...this.CREDITO_INACTIVE_ROUTES];

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// USERVALIDATION
		switch (this.clientsService.getUser()) {
			case "Administrador":
				return this.authenticationService.isAuth();
			case "Asistente":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.ASISTENTE_INACTIVE_ROUTES);
			case "Credito":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.CREDITO_INACTIVE_ROUTES);
			case "Legal":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.LEGAL_INACTIVE_ROUTES);
			case "Verificacion":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.VERIFICACION_INACTIVE_ROUTES);
			case "Cobranza":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.COBRANZA_INACTIVE_ROUTES);
			case "LegalCobranza":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.LEGAL_COBRANZA_INACTIVE_ROUTES);
			case "AsistenteBasico":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.ASISTENTE_BASICO_INACTIVE_ROUTES);
			case "Asesor":
				return this.authenticationService.isAuth() && this._inValidRoutes(state.url, this.ASESOR_INACTIVE_ROUTES);
			default:
				console.log("no puedes entrar");
				return false;
		}
	}

	private _inValidRoutes(route, inValidRoutes) {
		for (let i = 0, l = inValidRoutes.length; i < l; i++) {
			if (route === inValidRoutes[i]) {
				return false;
			}
		}
		return true;
	}
}
