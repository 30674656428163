import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../../core/auth/authentication.service";
import { ClientsService } from "../../../pages/components/shared/clients.service";
import { Router } from "@angular/router";

@Component({
	selector: "m-topbar",
	templateUrl: "./topbar.component.html",
})
export class TopbarComponent implements OnInit {
	@HostBinding("id") id = "m_header_nav";
	@HostBinding("class")
	classes = "m-stack__item m-stack__item--fluid m-header-head";
	tipoUsuario: string = "";
	@Input() searchType: any;

	constructor(private authService: AuthenticationService, private clientsService: ClientsService, private router: Router) {}

	public logout() {
		this.authService.logout(true);
		this.router.navigate(["/login"]);
	}

	ngOnInit() {
		this.tipoUsuario = this.clientsService.getUser();
	}
}
