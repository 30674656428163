import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from "@angular/core";
import { LayoutConfigService } from "./core/services/layout-config.service";
import { ClassInitService } from "./core/services/class-init.service";
import { TranslationService } from "./core/services/translation.service";
import * as objectPath from "object-path";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { PageConfigService } from "./core/services/page-config.service";
import { filter } from "rxjs/operators";
import { AuthenticationService } from "./core/auth/authentication.service";

// language list
import { locale as esLang } from "./config/i18n/es";
import { ClientsService } from "./content/pages/components/shared/clients.service";

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

@Component({
	// tslint:disable-next-line:component-selector
	selector: "body[m-root]",
	templateUrl: "./app.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnInit {
	title = "Metronic";

	@HostBinding("style") style: any;
	@HostBinding("class") classes: any;

	constructor(private layoutConfigService: LayoutConfigService, private classInitService: ClassInitService, private sanitizer: DomSanitizer, private translationService: TranslationService, private router: Router, private pageConfigService: PageConfigService, private authenticationService: AuthenticationService, private clientsService: ClientsService) {
		// user is loged in
		this.authenticationService.isAuthorized().subscribe((res) => {
			this.authenticationService.setAuth(!!res);
			// redirect to login if the user is not authenticated
			if (!this.authenticationService.isAuth()) {
				this.router.navigateByUrl("/login");
			}
			// si es verificacion mandar a la url siempre
			if (this.clientsService.getUser() === "Verificacion") {
				this.router.navigate(["/credit/verificacion"]);
			}

			// si es verificacion mandar a la url siempre
			if (this.clientsService.getUser() === "Asesor") {
				this.router.navigate(["/tickets"]);
			}
		});

		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe((classes) => {
			// get body class array, join as string classes and pass to host binding class
			setTimeout(() => (this.classes = classes.body.join(" ")));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe((model) => {
			this.classInitService.setConfig(model);

			this.style = "";
			if (objectPath.get(model.config, "self.layout") === "boxed") {
				const backgroundImage = objectPath.get(model.config, "self.background");
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle("background-image: url(" + objectPath.get(model.config, "self.background") + ")");
				}
			}
		});

		// register translations
		this.translationService.loadTranslations(esLang);

		// override config by router change from pages config
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
			this.layoutConfigService.setModel({ page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), "config") }, true);
		});
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}
}
