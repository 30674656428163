import { BehaviorSubject, from } from "rxjs";
import { Injectable } from "@angular/core";
import { MenuConfigService } from "../menu-config.service";
import { ClassInitService } from "../class-init.service";
import * as objectPath from "object-path";
import { LayoutConfigService } from "../layout-config.service";
import { ClientsService } from "../../../content/pages/components/shared/clients.service";

@Injectable()
export class MenuAsideService {
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

	isDropdown: number = 0;
	dropdownTimeout: number;
	isScrollable: number = 0;

	constructor(private menuConfigService: MenuConfigService, private layoutConfigService: LayoutConfigService, private clientsService: ClientsService) {
		// get menu list
		this.menuConfigService.onMenuUpdated$.subscribe((model) => {
			setTimeout(() => {
				console.log(this.clientsService.getUser(), "=== Menu lateral izquierdo USUARIO");
				this._permissions(model);
			});
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe((config) => {
			if (objectPath.get(config, "config.aside.left.fixed")) {
				this.isScrollable = 1;
				this.isDropdown = 0;
			}

			if (!objectPath.get(config, "config.aside.left.fixed") && !objectPath.get(config, "config.menu.aside.desktop_and_mobile.submenu.accordion")) {
				this.isScrollable = 0;
				this.isDropdown = 1;
				this.dropdownTimeout = objectPath.get(config, "config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout");
			}
		});
	}

	private _permissions(model): void {
		const items = objectPath.get(model.config, "aside.items");
		// USERVALIDATION
		switch (this.clientsService.getUser()) {
			case "Administrador":
				return this.menuList$.next(items);
			case "Asistente":
				const asistente = items.filter((i) => i.title === "Creditos" || i.title === "Legal" || i.title === "Reportes");
				return this.menuList$.next(asistente);
			case "Credito":
				const credito = items.filter((i) => i.title === "Creditos" || i.title === "Reportes");
				return this.menuList$.next(credito);
			case "Legal":
				const legal = items.filter((i) => i.title === "Legal");
				return this.menuList$.next(legal);
			case "Verificacion":
				const verificacion = items.filter((t) => t.title === "Creditos");
				verificacion[0].submenu = [verificacion[0].submenu.find((t) => t.title === "Verificación")];
				return this.menuList$.next(verificacion);
			case "LegalCobranza":
				const legalCobranza = items.filter((i) => i.title === "Reportes" || i.title === "Legal" || i.title === "Legal");
				return this.menuList$.next(legalCobranza);
			case "Cobranza":
				const cobranza = items.filter((i) => i.title === "Reportes" || i.title === "Legal");
				const _legal = cobranza.find((el) => (el.title = "Legal"));
				_legal.submenu = _legal.submenu.filter((el) => el.title === "Status Juicio");
				return this.menuList$.next(cobranza);
			case "Asesor":
				const tickets = items.filter((i) => i.title === "Tickets");
				return this.menuList$.next(tickets);
		}
	}
}
