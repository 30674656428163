import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import * as myGlobals from './globals.service';
import * as moment from 'moment';

@Injectable()
export class PDFService {
  private _options = { year: 'numeric', month: 'long', day: 'numeric' };
  private _tableTotal: number = 0;
  private _COLUMS = [
    'FECHA',
    'MONTO',
    'CARGO MORATORIO',
    'TOTAL'
  ];
	private _infoPrincipal = {
		empresa: {
			'person': '',
			'street': 'Alixco 107, Mitras Centro',
			'city': 'Monterrey, N.L',
			'phone': 'Tel: 8310-84-15'
		},
		credito: {
			'Folio': '',
			'Fecha de hoy': new Date().toLocaleDateString('es-ES', this._options),
			'Fecha de inicio del crédito': '',
			'Fecha de vencimiento': '',
    },
    titular: {
      'Nombre': '',
      'Domicilio': '',
      'Colonia': '',
      'Municipio': '',
      'Estado': '',
      'Teléfono': '',
      'Teléfono Celular': ''
    },
    aval: {
      'Nombre': '',
      'Domicilio': '',
      'Colonia': '',
      'Municipio': '',
      'Estado': '',
      'Teléfono': '',
      'Teléfono Celular': ''
    },
    descripcion: {
      'descripcion': (data) => `El presente estado de cuenta corresponde a los pagos vencidos referentes al crédito obtenido por el cliente: ${data.nombre} con nuestra empresa, el cual fue autorizado por un monto total de $${data.total} el día ${data.fechaInicio}, con un compromiso de pago de $${data.montoPago} semanales, durante un periodo de 16 semanas, debiendo tener como fecha de terminación la fecha ${data.fechaFin}.`,
      'nombre': '',
      'total': 0,
      'montoPago': 0,
      'fechaInicio': '',
      'fechaFin': ''
    },
    condiciones: {
      'Plazo': 'Plazo del Crédito: 16 semanas',
      'Interes': 'Interés Moratorio del 50% por cada pago vencido',
      'Cobranza': 'Gastos de cobranza: 30% sobre el saldo vencido'
    },
    verificacion: {
      'asesor': '',
      'usuario': '',
      'cantidadSolicidata': 0,
      'cantidadSugerida': 0,
      'viabilidadJuridica': null,
      'gafete': null,
      'uniforme': null,
      'comprendePoliticas': null
    }
  };
  private _toMoney(quantity: number): string {
    return `$ ${quantity}.00`;
  }

	generarPDF(cliente, tipo, callback): void {
		// creamos el documento
		const doc = new jsPDF('p', 'pt');
		const toDate = (date) => new Date(date).toLocaleDateString('es-ES', this._options);
		// seteamos las fuentes
    this._setFont(doc);
    // seteamos el total a 0
    this._tableTotal = 0;

		// ===================
		// SETTINGS
		// ===================
		const fontSizes = {
			TitleFontSize: 14,
			SubTitleFontSize: 12,
			NormalFontSize: 10,
			SmallFontSize: 9
		};
		const lineSpacing = 12;

		const startX = 57;
		const startY = 80; // bit more then 45mm

		// // ===================
		// // INFO EMPRESA
		// // ===================
		const img = myGlobals.logo;
		doc.addImage(img, 'PNG', 250, 30, 120, 60);

		// ===================
		// CREDITO
    // ===================
    if (tipo === 'EstadoDeCuenta') {
      this._infoPrincipal['credito']['Folio'] = cliente.folio;
      this._infoPrincipal['credito']['Fecha de inicio del crédito'] = toDate(cliente.credito.fechaDeposito);
      this._infoPrincipal['credito']['Fecha de vencimiento'] = toDate(cliente.credito.fechaTerminacion);

      // ===================
      // TITULAR
      // ===================
      this._infoPrincipal['titular']['Nombre'] = cliente.nombre;
      this._infoPrincipal['titular']['Domicilio'] = cliente.domicilio || '';
      this._infoPrincipal['titular']['Colonia'] = cliente.colonia || '';
      this._infoPrincipal['titular']['Municipio'] = cliente.municipio || '';
      this._infoPrincipal['titular']['Estado'] = cliente.estado || '';
      this._infoPrincipal['titular']['Teléfono'] = `Tel: ${cliente.telefonoCasa || ''}`;
      this._infoPrincipal['titular']['Teléfono Celular'] = `Celular: ${cliente.celular || ''}`;

      // ===================
      // AVAL
      // ===================
      const aval = cliente.avales.find(_aval => _aval.nombre.length > 0) || {};
      this._infoPrincipal['aval']['Nombre'] = aval.nombre;
      this._infoPrincipal['aval']['Domicilio'] = aval.domicilio || '';
      this._infoPrincipal['aval']['Colonia'] = aval.colonia || '';
      this._infoPrincipal['aval']['Municipio'] = aval.municipio || '';
      this._infoPrincipal['aval']['Estado'] = aval.estado || '';
      this._infoPrincipal['aval']['Teléfono'] = aval.telefono || '';
      this._infoPrincipal['aval']['Teléfono Casa'] = aval.telefonoCasa || '';

      // ===================
      // DESCRIPCION
      // ===================
      this._infoPrincipal['descripcion']['nombre'] = cliente.nombre;
      this._infoPrincipal['descripcion']['total'] = cliente.credito.monto;
      this._infoPrincipal['descripcion']['montoPago'] = cliente.credito.montoPago;
      this._infoPrincipal['descripcion']['fechaInicio'] = toDate(cliente.credito.fechaDeposito);
      this._infoPrincipal['descripcion']['fechaFin'] = toDate(cliente.credito.fechaTerminacion);
    }

		// ===================
		// CUSTOM RULES
		// ===================
		switch (tipo) {
			case 'EstadoDeCuenta':
				this._estadoDeCuenta(doc, cliente, fontSizes, lineSpacing, startY);
        break;
      case 'Verificacion':
        this._verificacion(doc, cliente, fontSizes, lineSpacing, startY);
        break;
			default:
				break;
    }
    // call calback after everything is build
    callback();
  }

  private _setFont(doc) {
		doc.setFont('Helvetica', 'normal');
  }

  private _verificacion(doc, cliente, fontSizes, lineSpacing, startY) {
    this._verificacionSecciones(doc, lineSpacing, startY + 20, cliente, this._infoPrincipal['empresa']);
		doc.save(`verifiacion`);
  }

	private _estadoDeCuenta(doc, cliente, fontSizes, lineSpacing, startY): void {
    this._secciones(doc, this._infoPrincipal, fontSizes.SmallFontSize, lineSpacing, startY + 20, cliente);
		doc.save(`estado_de_cuenta_${cliente.folio}`);
	}

  private _verificacionSecciones(doc, lineSpacing, startY, data, datosEmpresa) {
    let startX = 50;
    const pageWidth = doc.internal.pageSize.getWidth();
    const endX =  pageWidth - startX;
    const thirdX = (pageWidth - startX * 2) / 3;
    const SEPARATION_SPACE = 15;
    const SEPARATION_SECTION = 25;
    console.log(data)
    // -------SEPARATOR----------
    this._separator(doc, startX, startY, endX, startY);
    startY += lineSpacing / 3;

    doc.setFontSize(10);

    // ------- INFO DE LA EMPRESA ---------------------
    this._listItem(doc, datosEmpresa, 'REPORTE DE VERIFICACION', startX, startY, lineSpacing, false);
    startX += thirdX;
    startX += thirdX - 40;

    // =====
    let X = 55;
    let Y = 185;
    // -------SEPARATOR----------
    this._separator(doc, X, Y, endX, startY);
    Y += SEPARATION_SECTION;

    // -------ASESOR---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `ASESOR:`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['asesor']}`, X + 48, Y);
    Y += SEPARATION_SPACE;

    // -------USUARIO---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `CLIENTE:`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cliente']}`, X + 48, Y);
    Y += SEPARATION_SPACE;

    // -------CANTIDAD SOLICITADA---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `CANTIDAD SOLICITADA:`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cantidadSolicitada']}`, X + 122, Y);
    Y += SEPARATION_SPACE;

    // -------CANTIDAD SUGERIDA---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `CANTIDAD SUGERIDA:`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cantidadSugerida']}`, X + 113, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------VIABILIDAD JURIDICA---------------------
    let leftX = 340;
    let leftY = 210;

    doc.setFontStyle("bold");
    this._texto(doc, `VIABILIDAD JURIDICA:`, leftX, leftY);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['viabilidadJuridica'] === 'true' ? 'SI' : 'NO'}`, leftX + 112, leftY);

    // ------GAFETE---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `GAFETE:`, leftX, leftY + SEPARATION_SPACE);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['gafete'] === 'true' ? 'SI' : 'NO'}`, leftX + 45, leftY + SEPARATION_SPACE);
    leftY += SEPARATION_SPACE;

    // ------UNIFORME---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `UNIFORME:`, leftX, leftY + SEPARATION_SPACE);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['uniforme'] === 'true' ? 'SI' : 'NO'}`, leftX + 57, leftY + SEPARATION_SPACE);
    leftY += SEPARATION_SPACE;

    // ------COMPRENDE POLITICAS---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `COMPRENDE POLITICAS DE CREDITO:`, leftX, leftY + SEPARATION_SPACE);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['comprendePoliticas'] === 'true' ? 'SI' : 'NO'}`, leftX + 190, leftY + SEPARATION_SPACE);
    leftY += SEPARATION_SPACE;

    // ------CUESTIONARIO---------------------
    this._texto(doc, `1.- CONOCE LAS POLITICAS DE CRDITO Y COBRANZA DE LA EMPRESA?`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;
    // -------MONTO PAGO---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `A. MONTO DEL PAGO (10% SOBRE EL MONTO DEL CREDITO):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['montoPago'] === 'true' ? 'SI' : 'NO'}`, X + 305, Y);
    Y += SEPARATION_SPACE;
    // -------DIA Y HORA---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `B. DIA Y HORA DE PAGO (SABADO 3PM):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['diaPago'] === 'true' ? 'SI' : 'NO'}`, X + 202, Y);
    Y += SEPARATION_SPACE;
    // -------INTERESES---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `C. INTERESES MORATORIOS (50% SOBRE PAGO VENCIDO):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['interes'] === 'true' ? 'SI' : 'NO'}`, X + 294, Y);
    Y += SEPARATION_SPACE;
    // -------GASTOS DE COBRANZAS---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `D. GASTOS DE COBRANZA (30% SOBRE SALDO VENCIDO):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['gastosCobranza'] === 'true' ? 'SI' : 'NO'}`, X + 292, Y);
    Y += SEPARATION_SPACE;
    // -------PROCESO JUDICIAL---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `D. PROCESO JUDICISL (A PARTIR DEL 3ER PAGO):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['procesoJudicial'] === 'true' ? 'SI' : 'NO'}`, X + 249, Y);
    Y += SEPARATION_SPACE;
    // -------DESTINO DEL CREDITO---------------------
    doc.setFontStyle("bold");
    this._texto(doc, `D. DESTINO DEL CREDITO (PRESTANOMBRES):`, X, Y);
    doc.setFontStyle("normal");
    this._texto(doc, `${data['cuestionario']['destinoCredito'] === 'true' ? 'SI' : 'NO'}`, X + 234, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;


    // ------PREGUNTA2---------------------
    this._texto(doc, `2.- COMO CONOCE A SU ASESOR DE CREDITO?`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['conoceAsesor']}`, X , Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA3---------------------
    this._texto(doc, `3.- EL ASESOR LE COBRO ALGUN DINERO POR EL TRAMITE DEL CRÈDITO?`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['asesorCobro']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA4---------------------
    this._texto(doc, `3.- COMENTARIOS Y PERCEPCIONES DEL CLIENTE Y DOMICILIO`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['comentarios']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA5---------------------
    this._texto(doc, `Condiciones de las garantias`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['condicionesGarantias']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA6---------------------
    this._texto(doc, `Condiciones de la vivienda`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['condicionesVivienda']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA7---------------------
    this._texto(doc, `Orden y Limpieza`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['condicionesOrden']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;

    // ------PREGUNTA8---------------------
    this._texto(doc, `Condiciones de las zona`, X, Y);
    Y += SEPARATION_SPACE;
    this._texto(doc, `${data['cuestionario']['condicionesZona']}`, X, Y);
    Y += SEPARATION_SPACE + SEPARATION_SECTION;
  }

	private _secciones(doc, data, fontSize, lineSpacing, startY, cliente) {
    let startX = 50;
    const pageWidth = doc.internal.pageSize.getWidth();
    const endX =  pageWidth - startX;
    const thirdX = (pageWidth - startX * 2) / 3;

    // -------SEPARATOR----------
    this._separator(doc, startX, startY, endX, startY);
    startY += lineSpacing / 3;

    doc.setFontSize(fontSize);

    // ------- INFO DE LA EMPRESA ---------------------
    this._listItem(doc, data.empresa, 'CREDIPREST', startX, startY, lineSpacing, false);
    startX += thirdX;
    startX += thirdX - 40;


    // -------TIPO DE ANEXO---------------------
    let _startY = this._listItem(doc, data.credito, 'ESTADO DE CUENTA', startX, startY, lineSpacing, true, true);


    // -------SEPARATOR----------
    startX -= thirdX + 120;
    this._separator(doc, startX, _startY, endX, _startY);


    // ------- TITULAR ---------------------
    _startY += 12;
    this._listItem(doc, data.titular, 'TITULAR', startX, _startY, lineSpacing, false);

    // ------- AVAL ---------------------
    startX += thirdX;
    startX += thirdX - 40;
    _startY = this._listItem(doc, data.aval, 'AVAL', startX, _startY, lineSpacing, false);

    // ------- DESCRIPCION ---------------------
    _startY += 8;
    startX = 50;
    const startTextX = startX;
    const startTextY = _startY;
    this._texto(doc, data.descripcion.descripcion(data.descripcion), startTextX, startTextY);

    _startY += 55;
    // ------- TABLA TITULO---------------------
    doc.setFontSize(11);
    doc.setFontStyle('bold');
    doc.text('PAGOS VENCIDOS',  startX, _startY);

    _startY += 10;
    // ------- TABLA ---------------------
    doc.autoTable({
      head: [this._COLUMS],
      body: this._tabla(cliente),
      startY: _startY,
      margin: {left: 40}
    });

    // -------SEPARATOR----------
    let finalTabla = doc.previousAutoTable.finalY;
    finalTabla += 5;
    this._separator(doc, startX, doc.previousAutoTable.finalY + 5, endX, finalTabla);

    // ------- TABLA TOTAL ---------------------
    finalTabla += 25;
    doc.setFontSize(12);
    doc.text(`TOTAL: ${this._toMoney(this._tableTotal)}`,  startX + 385, finalTabla);

    // ------- CONDICIONES ---------------------
    finalTabla -= 10;
    doc.setFontSize(8);
    this._listItem(doc, data.condiciones, 'CONDICIONES COMERCIALES', startX, finalTabla, 9, true, false);
  }

  private _tabla(cliente): any {
    if (!cliente['credito']) {
      return [];
    }

    if (!cliente['credito']['faltante']) {
      return [];
    }

    const clientes = [];
    for (let i = 0, l = cliente['credito']['faltante'].length; i < l; i++) {
      const c = cliente['credito']['faltante'][i];
      if (c.monto.length > 0) {
        const _cliente = [];
        const interes = (parseInt(c.monto, 10) / 2);
        const total = (parseInt(c.monto, 10) + interes);
        this._tableTotal += total;
        _cliente.push(
          new Date(moment(c.fecha, 'DD/MM/YYYY').format('MM/DD/YYYY')).toLocaleDateString('es-ES', this._options), // fecha
          this._toMoney(parseInt(c.monto, 10)), // monto del pago
          this._toMoney(interes), // interes moratorio
          this._toMoney(total)
        );
        clientes.push(_cliente);
      }
    }

    return clientes;
  }

  private _texto(doc, data, startX, startY) {
    const splitTitle = doc.splitTextToSize(data, 480);
    doc.text(splitTitle,  startX, startY);
  }

  private _separator(doc, startX, startY, endX, endY): any {
    doc.setDrawColor(206, 218, 192);
    doc.setLineWidth(0.5);
    doc.line(startX, startY, endX, startY);
  }

	private _listItem(doc, data, headline, startX, startY, lineSpacing, esCreditoInfo, tieneSeparador = false) {
		const padding = lineSpacing / 2;
    let tempY = startY;

    doc.setFontType('bold');
    doc.text(headline, startX + padding, tempY += lineSpacing);
    tempY += lineSpacing;

		doc.setFontType('normal');
		for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const _key = `${key}: `;
        let text = '';
        if (esCreditoInfo) {
          text += _key;
        }
        text += `${data[key]}`;
        doc.text(text, startX + padding, tempY += lineSpacing);
      }
		}

    if (tieneSeparador) {
      doc.setDrawColor(206, 218, 192);
      doc.setLineWidth(1);
      doc.line(startX + 1, startY + padding, startX + 1, tempY + padding / 3);
    }

    return tempY + 12;
	}
}
