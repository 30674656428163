import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthenticationService } from "../../core/auth/authentication.service";
import { UrlService } from "../services/url.service";

@Injectable()
export class ClientService {
	private API_URL;
	private API_ENDPOINT_CLIENTS = "/clientes";
	private API_ENDPOINT_ASESORES = "/asesores";
	private API_ENDPOINT_GERENTES = "/gerentes";
	private API_ENDPOINT_EMPLEADOS = "/RH";
	private API_ENDPOINT_VERIFICACION = "/verificacion";
	private API_ENDPOINT_AJUSTES = "/ajustes";

	private API_ENDPOINT_STATUS_JUICIO = "/statusjuicios";
	private API_ENDPOINT_AGENDA_ACTIVIDAES = "/agendaactividades";
	private API_ENDPOINT_CONVENIOS = "/convenios";
	private API_ENDPOINT_LEGAL = "/legal";
	private API_ENDPOINT_METAS = "/metas";
	private API_ENDPOINT_BURO = "/buro";
	private API_ENDPOINT_REPORTE_BURO = "/reporteburo";
	private API_ENDPOINT_TICKETS = "/tickets";

	public CANTIDAD_CASILLAS_RECUPERACION = 30;
	public CANTIDAD_CASILLAS_FALTANTE = 16;

	constructor(private http: HttpClient, private authService: AuthenticationService, private urlService: UrlService) {
		this.API_URL = this.urlService.getApiUrl();
	}

	// =====
	// ========== Status Juicio
	// =====
	// GET ALL
	public getAllStatusJuicio(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_STATUS_JUICIO}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneStatusJuicio(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_STATUS_JUICIO}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postStatusJuicio(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_STATUS_JUICIO}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putStatusJuicio(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_STATUS_JUICIO}/${payload.folio}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// =====
	// ========== Legal
	// =====
	// GET ALL
	public getAllLegal(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_LEGAL}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneLegal(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_LEGAL}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postLegal(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_LEGAL}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putLegal(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_LEGAL}/${payload.folio}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// =====
	// ========== Agenda Actividades
	// =====
	// GET ALL
	public getAllAgendaActividades(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_AGENDA_ACTIVIDAES}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneAgendaActividades(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_AGENDA_ACTIVIDAES}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postAgendaActividades(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_AGENDA_ACTIVIDAES}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putAgendaActividades(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_AGENDA_ACTIVIDAES}/${payload.folio}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// =====
	// ========== Convenios
	// =====
	// GET ALL
	public getAllConvenios(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_CONVENIOS}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneConvenio(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_CONVENIOS}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postConvenios(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_CONVENIOS}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putConvenios(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_CONVENIOS}/${payload.folio}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// =====
	// ========== METAS
	// =====
	// GET ALL
	public getAllMetas(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_METAS}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneMeta(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_METAS}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postMeta(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_METAS}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putMeta(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_METAS}/${payload.id}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// DELETE
	public borrarMeta(id): Observable<any> {
		return this.http.delete(`${this.API_URL}${this.API_ENDPOINT_METAS}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// =====
	// ========== BURO
	// =====
	// GET ALL
	public getAllBuro(payload): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				responseType: "text",
				Accept: "application/csv",
			}),
		};

		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_REPORTE_BURO}`, payload, httpOptions).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// GET ONE
	public getOneBuro(id): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_BURO}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// POST
	public postBuro(payload): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_BURO}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// PUT
	public putBuro(payload): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_BURO}/${payload.detCuentaActual}`, payload).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// CLIENTES
	public clientes(payload = null): Observable<any> {
		const validation = payload ? `?q=${payload}` : "?a=true"; // si tiene payload traemos solo lo que pide si no traemos todos
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_CLIENTS}${validation}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public getCliente(folio: string): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_CLIENTS}/0?folio=${folio}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public nuevoCliente(client, formulario): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_CLIENTS}`, this._cliente(formulario)).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editarCliente(cliente): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_CLIENTS}/${cliente.id}`, cliente).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public legal(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_LEGAL}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public agregarLegal(body, type): Observable<any> {
		if (type === "PUT") {
			return this.http.put(`${this.API_URL}${this.API_ENDPOINT_LEGAL}/${body.folio}`, body).pipe(
				map((result: any) => result),
				catchError((err) => {
					this.handleError(err.status);
					return [err];
				})
			);
		} else {
			return this.http.post(`${this.API_URL}${this.API_ENDPOINT_LEGAL}`, body).pipe(
				map((result: any) => result),
				catchError((err) => {
					console.log(err);
					this.handleError(err.status);
					return [err];
				})
			);
		}
	}

	// ASESORES
	public agregarAsesor(nombre): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_ASESORES}`, { nombre }).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public borrarAsesor(id): Observable<any> {
		return this.http.delete(`${this.API_URL}${this.API_ENDPOINT_ASESORES}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public asesores(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_ASESORES}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// GERENTES
	public agregarGerente(nombre): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_GERENTES}`, { nombre }).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public borrarGerente(id): Observable<any> {
		return this.http.delete(`${this.API_URL}${this.API_ENDPOINT_GERENTES}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public gerentes(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_GERENTES}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}
	// == / GERENTES

	// AJUSTES
	public agregarAjustes(data): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_AJUSTES}`, data).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editarAjustes(data): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_AJUSTES}/1`, data).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public ajustes(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_AJUSTES}/1`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// EMPLEADOS
	public agregarEmpleado(nombre): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_EMPLEADOS}`, { nombre }).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public borrarEmpleado(id): Observable<any> {
		return this.http.delete(`${this.API_URL}${this.API_ENDPOINT_EMPLEADOS}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public empleados(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_EMPLEADOS}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editarEmpleado(empleado): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_EMPLEADOS}/${empleado.id}`, empleado).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// ============= FINANZAS
	// ============= FINANZAS
	// ============= FINANZAS

	public agregarFinanzas(body, endpoint): Observable<any> {
		return this.http.post(`${this.API_URL}/${endpoint}`, body).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public borrarFinanzas(id, endpoint): Observable<any> {
		return this.http.delete(`${this.API_URL}/${endpoint}/${id}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public finanzas(endpoint): Observable<any> {
		return this.http.get(`${this.API_URL}/${endpoint}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editaFinanzas(body, endpoint): Observable<any> {
		return this.http.put(`${this.API_URL}/${endpoint}/${body.id}`, body).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public verificacion(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_VERIFICACION}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public agregarVerificacion(body): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_VERIFICACION}`, body).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editarUsuario(body, id): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_VERIFICACION}/${id}`, body).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	private _cliente(formulario): any {
		const fechas = formulario.esNegocio ? 20 : 16;
		const getFaltante = () => {
			const faltante = [];
			for (let i = 0, l = fechas; i < l; i++) {
				faltante.push({
					fecha: "",
					monto: "",
				});
			}
			return faltante;
		};
		const body = {
			folio: formulario.folio.toUpperCase(),
			pagado: formulario.pagado,
			asesor: formulario.asesor,
			nombre: formulario.nombre,
			domicilio: formulario.domicilio,
			colonia: formulario.colonia,
			cp: formulario.cp,
			municipio: formulario.municipio,
			estado: formulario.estado,
			celular: formulario.celular,
			telefonoCasa: formulario.telefonoCasa,
			cuentaBancaria: formulario.cuentaBancaria,
			banco: formulario.banco,
			esNegocio: formulario.esNegocio,
			avales: [
				{
					nombre: formulario.avalNombre_1,
					domicilio: formulario.avalDomicilio_1,
					colonia: formulario.avalColonia_1,
					municipio: formulario.avalMunicipio_1,
					estado: formulario.avalEstado_1,
					telefono: formulario.avalTelefono_1,
					telefonoCasa: formulario.avalTelefonoCasa_1,
				},
				{
					nombre: formulario.avalNombre_2,
					domicilio: formulario.avalDomicilio_2,
					colonia: formulario.avalColonia_2,
					municipio: formulario.avalMunicipio_2,
					estado: formulario.avalEstado_2,
					telefono: formulario.avalTelefono_2,
					telefonoCasa: formulario.avalTelefonoCasa_2,
				},
			],
			referencias: [
				{
					nombre: formulario.referenciaNombre_1,
					telefonoCasa: formulario.referenciaTelefono_1,
				},
				{
					nombre: formulario.referenciaNombre_2,
					telefonoCasa: formulario.referenciaTelefono_2,
				},
				{
					nombre: formulario.referenciaNombre_3,
					telefonoCasa: formulario.referenciaTelefono_3,
				},
			],
			credito: {
				faltante: getFaltante(),
			},
		};
		return body;
	}

	private handleError(status) {
		if (status === 401) this.authService.logout(true);
	}

	// ====== TICKETS =====
	public agregarTicket(data): Observable<any> {
		return this.http.post(`${this.API_URL}${this.API_ENDPOINT_TICKETS}`, data).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public cargarTickets(): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_TICKETS}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public filtrarTickets(params): Observable<any> {
		return this.http.get(`${this.API_URL}${this.API_ENDPOINT_TICKETS}?${params}`).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	public editarTicket(data, id): Observable<any> {
		return this.http.put(`${this.API_URL}${this.API_ENDPOINT_TICKETS}/${id}`, data).pipe(
			map((result: any) => result),
			catchError((err) => {
				this.handleError(err.status);
				return [err];
			})
		);
	}

	// public eliminarTicket(): Observable<any> {
	// 	return this.http.get(`${this.API_URL}${this.API_ENDPOINT_TICKETS}/1`).pipe(
	// 		map((result: any) => result),
	// 		catchError((err) => {
	// 			this.handleError(err.status);
	// 			return [err];
	// 		})
	// 	);
	// }
}
